import React from "react";
import AppcheckBase from "@src/layouts/AppcheckBase";

function AppcheckParentSchoolStory() {
  return (
    <AppcheckBase>
      <a
        id="app-trigger"
        href="https://home.classdojo.com/#/login"
        data-app="https://home.classdojo.com/#/login"
        data-app-ios="classdojo://p/st"
        data-app-android="classdojo://p/st"
        data-store-android="com.classdojo.android"
        data-store-ios="552602056"
        style={{ color: "#fff" }}
      >
        ClassDojo App
      </a>
    </AppcheckBase>
  );
}

export default AppcheckParentSchoolStory;
